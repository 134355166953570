export default {
  header: "Projection",
  "paragraph-1":
    "Retrouvez ci-dessous les données technico-financières calculées sur la durée des garanties du fabricant.",
  "full-period": "Sur 25 ans",

  rows: {
    data: {
      title: "Données techniques",
      consumption: "Consommation",
      "consumption-in-peak": "Consommation en heure pleines",
      "consumption-in-off-peak": "Consommation en heures creuses",
      "consumption-in-base": "Consommation",
      production: "Production",
      "consumption-exported-to-grid": "Injection de production",
      "consumption-exported-to-virtual-battery":
        "Charge de la batterie virtuelle",
      "consumption-from-solar-direct": "Autoconsommation instantanée",
      "consumption-from-solar": "Autoconsommation",
      "consumption-imported-from-grid": "Consommation du réseau",
      "consumption-imported-from-virtual-battery":
        "Décharge de la batterie virtuelle",
    },
    bills: {
      title: "Facture d'électricité",
      "electricity-bill-without-solar": {
        title: "Facture d'électricité sans solaire",
        consumption: "Consommation",
        "variable-before-tax-amount": "Consommation prélevée sur réseau",
        "variable-tariff-expenditure": {
          title: "Consommation",
          "consumption-spending-during-peak":
            "Dépenses en consommation en heures pleines",
          "consumption-spending-during-off-peak":
            "Dépenses en consommation en heures creuses",
        },

        "fixed-tariff-expenditure": "Abonnement",
        taxes: {
          title: "Taxes et TVA",
          "local-taxes": "Taxe CPSE",
          "cta-taxes": "Taxe CTA",
          "fixed-tariff-taxes": "TVA 5,5%",
          "variable-tariff-taxes": "TVA 20%",
        },
        total: "Somme TTC",
      },
      "electricity-bill-with-solar": {
        title: "Facture d'électricité avec solaire",
        consumption: "Consommation",
        "consumption-taken-from-grid": "Consommation importée depuis le réseau",
        "consumption-from-solar-local-consumption":
          "Autoconsommation électrique",
        "variable-tariff-expenditure": "Dépenses restantes en consommation",
        "fixed-tariff-expenditure": {
          title: "Abonnement",
          "enedis-subscription-cost": "Abonnement fournisseur énergie",
          "enedis-grid-connection-cost": "Majoration sur l'abonnement",
          "virtual-battery-fixed-cost": "Abonnement batterie virtuelle",
        },
        taxes: {
          title: "Taxes et TVA",
          "local-taxes": "Taxe CSPE",
          "cta-taxes": "Taxe CTA",
          "injection-taxes": "Taxe d'acheminement de l'injection",
          "fixed-tariff-taxes": "TVA 5,5%",
          "variable-tariff-taxes": "TVA 20%",
        },
        total: "Somme TTC",
      },
      "electricity-bill-savings": "Économies sur la facture d'électricité",
    },
    "cash-flow": {
      title: "Flux de trésorerie",
      "asset-cash-flows": {
        title: "Total produit d’exploitation",
        "electricity-bill-savings": "Économies sur la facture d'électricité",
        subsidies: "Aides de l'État",
        "roof-leasing-income": "Loyer photovoltaïque",
        "surplus-production-sold-revenues": {
          gross: "Revenu de vente avant imposition",
          production: "Production",
          "feed-in-tariff": "Tarif d'achat du kWh",
          net: "Revenu de vente après imposition",
          taxes: "Imposition sur revenu",
          total: "Revenu net de vente",
        },
        "storage-liquidation-value": {
          title: "Valeur liquidative de l'énergie stocké",
          "charge-change": "Évolution de l'énergie stocké",
          "liquidation-price": "Prix du kWh de l'énergie stocké",
          "charge-change-value": "Valeur de l'énergie stocké sur l'année",
          revaluation: "Revalorisation de l'énergie stocké",
          total: "Valeur cumulée de l'énergie stocké",
        },
      },
      "liability-cash-flow": {
        title: "Dépenses d'exploitation",
        "initial-investment": "Montant du devis",
        "enedis-construction-cost": "Coûts travaux d'Enedis",
        "home-insurance": "Frais d’assurance(s)",
        maintenance: "Frais de maintenance",
        "quality-control": "Frais contrôle de qualité",
        "virtual-battery-additional-costs": "Frais de batterie virtuelle",
        "additional-liabilities": "Budget supplémentaire",
        "financing-payments": "Mensualités",
      },
      "net-cash-flows": "Excédent brut d’exploitation",
      "debt-funding": "Apport en crédit",
      "equity-funding": "Apport au comptant",
      "net-cash-flows-unlevered": "Résultat d'exploitation",
      "net-cash-flows-levered": "Résultat financier",
      "cumulative-net-cash-flow-to-equity": "Cumulé du résultat net",
    },
    discounting: {
      title: "Actualisation",
      "discounted-asset-cash-flows": "Actualisation des encaissements",
      "discounted-liabilites": "Actualisation des décaissements",
      "discounted-net-cash-flow-to-equity":
        "Actualisation du solde de trésorerie",
      "discounted-cumulative-net-cash-flow-to-equity":
        "Cumulé actualisé du résultat net",
    },
    financing: {
      title: "Financement",
      loan: {
        costs: {
          "origination-fee": "Frais de courtage",
          "underwriting-fee": "Frais de souscription",
          "application-fee": "Frais de dossier",
          "appraisal-fee": "Frais d'évaluation",
          "insurance-fee": "Frais de l’assurance du crédit",
        },
        "equity-funding": "Apport au comptant",
        "debt-funding": "Apport en crédit",
        "debt-balance": "Solde du crédit",
        "debt-principal-payment": "Remboursement du principal",
        "debt-principal-prepayment": "Remboursement anticipé du principal",
        "debt-interest-payment": "Remboursement des intérêts",
        "additional-payments": "Frais de financement",
      },
    },
  },
}
